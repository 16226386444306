import React from "react";
import { connect } from "react-redux";
import OrdersTable from "../Shared/OrdersTable";
import { useHistory } from "react-router-dom";

const StoreOrders = (props) => {
  const { orders, hyperPackPriority, hpMode } = props;

  const history = useHistory();
  if (hpMode) {
    history.push("/NoteQueue");
  }

  const comparePriority = (a, b) => {
    if (a.priority < b.priority) {
      return -1;
    }
    if (a.priority > b.priority) {
      return 1;
    }
    return 0;
  };

  const compareHyperPackPriorityDesc = (a, b) => {
    if (a.isHyperPackable < b.isHyperPackable) {
      if (a.priority < b.priority) {
        return -1;
      }
      if (a.priority > b.priority) {
        return 1;
      }
      return 1;
    }
    if (a.isHyperPackable > b.isHyperPackable) {
      if (a.priority < b.priority) {
        return -1;
      }
      if (a.priority > b.priority) {
        return 1;
      }
      return -1;
    }
    return 0;
  };

  if (orders) {
    return (
      <OrdersTable
        parent="Line Information"
        orders={
          hyperPackPriority === 1
            ? orders
                .filter(
                  (o) =>
                    o.status === 1 ||
                    o.status === 2 ||
                    o.status === 3 ||
                    o.status === 5
                )
                .sort(comparePriority)
                .sort(compareHyperPackPriorityDesc)
            : orders
                .filter(
                  (o) =>
                    o.status === 1 ||
                    o.status === 2 ||
                    o.status === 3 ||
                    o.status === 5
                )
                .sort(comparePriority)
        }
      />
    );
  } else {
    return <div>Loading...</div>;
  }
};

const mapStateToProps = (state) => ({
  orders: state.order.orders,
  hpMode: state.system.hpMode,
  hyperPackPriority: state.system.hyperPackPriority,
});

export default connect(mapStateToProps, {})(StoreOrders);
