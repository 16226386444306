import React, { useEffect, useState, useRef, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
  IconButton,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { connect } from "react-redux";
import {
  updateLinePaused,
  updateSkipNotes,
  updateHpMode,
  updateAutoPilot,
  setSystemAction,
  updateAutoPilotBoxes,
  updateOutOfStock,
  forcePauseAction,
  suppressOutOfStockPopup,
} from "../../reduxActions/system";
import IOSSwitch from "./IOSSwitch";
import "./footer.scss";
import { formatTimeDelay } from "../Utils/dateTime";
import GroupedButton from "../Shared/GroupedButton";
import GroupedButtonOutOfStock from "../Shared/GroupedButtonOutOfStock";
import { PauseCircleFilled, PlayCircleFilled } from "@material-ui/icons";
import AutoPilotPopup from "../Control/AutoPilotFlavourPopup";
import { setSnackBarProps } from "../../reduxActions/snackbar";
import Dialogy from "../Shared/Dialogy";

const StyledCheckbox = withStyles((theme) => ({
  root: {
    color: "",
    "& .MuiIconButton-label": {
      position: "relative",
      zIndex: 0,
    },
    "&:not($checked) .MuiIconButton-label:after": {
      content: '""',
      left: 4,
      top: 4,
      height: 15,
      width: 15,
      position: "absolute",
      backgroundColor: "#002b80",
      zIndex: -1,
    },
  },
  checked: {
    color: "#006600 !important",
  },
}))(Checkbox);

const Footer = (props) => {
  const [refresh, setrefresh] = useState(false);
  const [boxes, setboxes] = useState({
    autoPilot06: 0,
    autoPilot12: 0,
    autoPilot18: 0,
    autoPilot24: 0,
    autoPilot36: 0,
  });
  const [isMobile, setisMobile] = useState();
  const [docWidth, setdocWidth] = useState();
  const [docHeight, setdocHeight] = useState();

  const [autoPilotPopup, setAutoPilotPopup] = useState(false);

  const {
    orders,
    updateLinePaused,
    updateSkipNotes,
    updateHpMode,
    updateAutoPilot,
    setSystemAction,
    updateAutoPilotBoxes,
    updateOutOfStock,
    forcePauseAction,
    suppressOutOfStockPopup,
    system: {
      linePaused,
      autoPilot,
      skipNotes,
      hpMode,
      autoPilot06,
      autoPilot12,
      autoPilot18,
      autoPilot24,
      autoPilot36,
      outOfStock,
      missingStock,
      errorMessage,
      suppressOutOfStockPopupTime,
      forcePause,
    },
    setSnackBarProps,
  } = props;

  useEffect(() => {
    setboxes({
      ...boxes,
      autoPilot06: autoPilot06,
      autoPilot12: autoPilot12,
      autoPilot18: autoPilot18,
      autoPilot24: autoPilot24,
      autoPilot36: autoPilot36,
    });
  }, [autoPilot06, autoPilot12, autoPilot18, autoPilot24, autoPilot36]);

  const latestLinePause = useRef(null);

  latestLinePause.current = linePaused;

  const ordersInLine = () => {
    let numOrdersInLine = 0;
    if (orders) {
      orders.forEach((o) => {
        if (o.status === 1 || o.status === 2 || o.status === 3) {
          numOrdersInLine += 1;
        }
      });
      return numOrdersInLine;
    }
  };

  const handleChangeAutoPilot = (field, value) => {
    const newValue = value ? 1 : 0;
    setboxes({ ...boxes, [field]: newValue });
    setrefresh(true);
  };

  useEffect(() => {
    if (refresh) {
      updateAutoPilotBoxes(boxes);
      setrefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    window.addEventListener("resize", function (event) {
      setdocWidth(document.documentElement.clientWidth);
      setdocHeight(document.documentElement.clientHeight);
    });
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      ) &&
      document.documentElement.clientWidth <
        document.documentElement.clientHeight &&
      document.documentElement.clientHeight <= 896
    ) {
      setisMobile(true);
      console.log("Mobile True");
      console.log(document.documentElement.clientWidth);
      console.log(document.documentElement.clientHeight);
    } else {
      setisMobile(false);
      console.log("Mobile False");
      console.log(document.documentElement.clientWidth);
      console.log(document.documentElement.clientHeight);
    }
  }, [docWidth, docHeight]);

  if (linePaused != null && boxes && isMobile != null) {
    return (
      <div>
        {autoPilotPopup && (
          <AutoPilotPopup
            open={autoPilotPopup}
            handleClose={(showSuccess = false) => {
              if (showSuccess) {
                setSnackBarProps("success", "Saved successfully", true);
              }
              setAutoPilotPopup(false);
            }}
            setSnackBarProps={setSnackBarProps}
          />
        )}
        <Dialogy
          open={
            hpMode &&
            outOfStock === 1 &&
            Date.now() - suppressOutOfStockPopupTime > 120
          }
          handleClose={() => {
            suppressOutOfStockPopup();
          }}
          details={{
            title: "Note Writer Out Of Stock",
            text: errorMessage,
            type: "outOfStockPopup",
          }}
          proceed={() => {
            suppressOutOfStockPopup();
            updateOutOfStock("Reattempt");
          }}
        />
        <div className="footer1">
          <Grid
            container
            className="footer-grid-container"
            direction="column"
            alignContent="space-between"
            justify="center"
          >
            <Grid item xs={3} className="footer-grid-items">
              {outOfStock === 1 ? (
                <GroupedButtonOutOfStock
                  updateOutOfStock={updateOutOfStock}
                  outOfStock={outOfStock}
                  missingStock={missingStock}
                />
              ) : (
                <Button
                  variant="contained"
                  className="robot-stock-ok"
                  color="primary"
                  size={isMobile ? "small" : "medium"}
                >
                  Stock
                </Button>
              )}
            </Grid>
            <Grid item xs={3} className="footer-grid-items">
              <Button
                variant="contained"
                color="primary"
                className={
                  isMobile ? "play-stop-button-mobile" : "play-stop-button"
                }
                size={isMobile ? "small" : "medium"}
                onClick={() => forcePauseAction(forcePause === 0 ? 1 : 0)}
              >
                {forcePause === 1 ? "Play" : "Pause"}
              </Button>
              {forcePause === 0 ? (
                <PlayCircleFilled
                  style={{
                    fontSize: "2.5em",
                    color: "green",
                    marginLeft: "10px",
                  }}
                />
              ) : (
                <PauseCircleFilled
                  style={{
                    fontSize: "2.5em",
                    color: "orange",
                    marginLeft: "10px",
                  }}
                />
              )}
            </Grid>
          </Grid>
        </div>
        <div className="footer2">
          <Grid
            container
            className="footer-grid-container"
            direction="column"
            alignContent="space-between"
            justify="center"
          >
            <Grid item xs={2} className="footer-grid-items">
              <Typography variant="h5" className="footer-grid-items-typography">
                HP Mode
              </Typography>
              <FormControlLabel
                control={
                  <IOSSwitch
                    checked={hpMode}
                    onClick={() => updateHpMode(!hpMode)}
                  />
                }
              />
            </Grid>
            <Grid item xs={2} className="footer-grid-items">
              <Typography variant="h5" className="footer-grid-items-typography">
                {"LINE "}
              </Typography>
              <FormControlLabel
                control={
                  <IOSSwitch
                    checked={!linePaused}
                    onClick={() => updateLinePaused(!linePaused)}
                  />
                }
              />
            </Grid>
            {!hpMode && (
              <>
                <Grid item xs={2} className="footer-grid-items">
                  <Typography
                    variant="h5"
                    className="footer-grid-items-typography"
                  >
                    Notes
                  </Typography>
                  <FormControlLabel
                    control={
                      <IOSSwitch
                        checked={!skipNotes}
                        onClick={() => updateSkipNotes(!skipNotes)}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={4} className="footer-grid-items">
                  <Typography
                    variant="h5"
                    className="footer-grid-items-typography"
                  >
                    {"Auto Pilot "}
                  </Typography>
                  <FormControlLabel
                    control={
                      <IOSSwitch
                        checked={!autoPilot}
                        onClick={() => updateAutoPilot(!autoPilot)}
                      />
                    }
                  />
                  <div className="autopilot-checkoxes">
                    <FormControlLabel
                      control={
                        <StyledCheckbox
                          checked={boxes.autoPilot06}
                          iconStyle={{ fill: "white" }}
                          onChange={(e) =>
                            handleChangeAutoPilot(
                              "autoPilot06",
                              e.target.checked
                            )
                          }
                          name="06-autopilot"
                          color="primary"
                          style={{ padding: "0px" }}
                        />
                      }
                      label="06"
                      labelPlacement="start"
                    />
                    <FormControlLabel
                      control={
                        <StyledCheckbox
                          checked={boxes.autoPilot12}
                          onChange={(e) =>
                            handleChangeAutoPilot(
                              "autoPilot12",
                              e.target.checked
                            )
                          }
                          name="12-autopilot"
                          color="primary"
                          style={{ padding: "0px" }}
                        />
                      }
                      label="12"
                      labelPlacement="start"
                      style={{ marginLeft: "3px" }}
                    />
                    <FormControlLabel
                      control={
                        <StyledCheckbox
                          checked={boxes.autoPilot18}
                          onChange={(e) =>
                            handleChangeAutoPilot(
                              "autoPilot18",
                              e.target.checked
                            )
                          }
                          name="18-autopilot"
                          color="primary"
                          style={{ padding: "0px" }}
                        />
                      }
                      label="18"
                      labelPlacement="start"
                    />
                    <FormControlLabel
                      control={
                        <StyledCheckbox
                          checked={boxes.autoPilot24}
                          onChange={(e) =>
                            handleChangeAutoPilot(
                              "autoPilot24",
                              e.target.checked
                            )
                          }
                          name="24-autopilot"
                          color="primary"
                          style={{ padding: "0px" }}
                        />
                      }
                      label="24"
                      labelPlacement="start"
                      style={{ marginLeft: "3px" }}
                    />
                    <FormControlLabel
                      control={
                        <StyledCheckbox
                          checked={boxes.autoPilot36}
                          onChange={(e) =>
                            handleChangeAutoPilot(
                              "autoPilot36",
                              e.target.checked
                            )
                          }
                          name="36-autopilot"
                          color="primary"
                          style={{ padding: "0px" }}
                        />
                      }
                      label="36"
                      labelPlacement="start"
                      style={{ marginLeft: "3px" }}
                    />
                    <FormControlLabel
                      control={
                        <IconButton
                          size="small"
                          color="primary"
                          style={{ padding: "0px" }}
                        >
                          <img
                            src="https://storage.googleapis.com/magnesiumassets/icons8-coffee-capsule-50%20(1).png"
                            alt="coffee-capsule-img"
                            style={{ padding: "0px", width: "25px" }}
                            onClick={() => setAutoPilotPopup(true)}
                          />
                        </IconButton>
                      }
                      style={{ paddingLeft: "1.2em" }}
                    />
                  </div>
                </Grid>
              </>
            )}
            <Grid item xs={2} className="footer-grid-items">
              <GroupedButton action={setSystemAction} />
            </Grid>
          </Grid>
        </div>
      </div>
    );
  } else {
    return <div>Loading...</div>;
  }
};

const mapStateToProps = (state) => ({
  system: state.system,
  orders: state.order.orders,
});

export default connect(mapStateToProps, {
  updateLinePaused,
  updateSkipNotes,
  updateHpMode,
  updateAutoPilot,
  setSystemAction,
  updateAutoPilotBoxes,
  updateOutOfStock,
  forcePauseAction,
  setSnackBarProps,
  suppressOutOfStockPopup,
})(Footer);
