import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  linePaused: false,
  fullStop: false,
  notes: true,
  hpMode: true,
  autoPilot: false,
  autoPilot06: 0,
  autoPilot12: 0,
  autoPilot18: 0,
  autoPilot24: 0,
  autoPilot36: 0,
  outOfStock: 0,
  missingStock: null,
  errorMessage: null,
  forcePause: 0,
  suppressOutOfStockPopupTime: 0,
  hyperPackPriority: 0,
};
const system = createReducer(initialState, {
  GET_SYSTEM_VALUES: (state, action) => {
    const {
      payload: {
        linePaused,
        notes,
        hpMode,
        autoPilot,
        autoPilot06,
        autoPilot12,
        autoPilot18,
        autoPilot24,
        autoPilot36,
        outOfStock,
        missingStock,
        errorMessage,
        forcePause,
        hyperPackPriority,
      },
    } = action;
    return {
      ...state,
      linePaused: linePaused === 1 ? true : false,
      notes: notes === 1 ? true : false,
      hpMode: hpMode === 1 ? true : false,
      autoPilot: autoPilot === 1 ? true : false,
      autoPilot06: autoPilot06,
      autoPilot12: autoPilot12,
      autoPilot18: autoPilot18,
      autoPilot24: autoPilot24,
      autoPilot36: autoPilot36,
      outOfStock: outOfStock,
      missingStock: missingStock,
      errorMessage: errorMessage,
      forcePause: forcePause,
      hyperPackPriority: hyperPackPriority,
    };
  },
  UPDATE_LINEPAUSED: (state, action) => {
    const { payload } = action;
    return {
      ...state,
      linePaused: payload,
    };
  },
  UPDATE_HP_MODE: (state, action) => {
    const { payload } = action;
    return {
      ...state,
      hpMode: payload,
    };
  },
  UPDATE_SKIP_NOTES: (state, action) => {
    const { payload } = action;
    return {
      ...state,
      skipNotes: payload,
    };
  },
  UPDATE_AUTOPILOT: (state, action) => {
    const { payload } = action;
    return {
      ...state,
      autoPilot: payload,
    };
  },
  CLEAR_OUTOFSTOCK: (state) => {
    return {
      ...state,
      outOfStock: 0,
      missingStock: null,
    };
  },
  FORCE_PAUSE: (state, action) => {
    const { payload } = action;
    return {
      ...state,
      forcePause: payload,
    };
  },
  
  SUPPRESS_OUT_OF_STOCK_POPUP: (state) => {
    return {
      ...state,
      suppressOutOfStockPopupTime: Date.now(),
    };
  },
});

export default system;
