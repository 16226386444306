import { createAction } from "@reduxjs/toolkit";
import axios from "axios";

export const setError = createAction("SET_ERROR");

export const sendSystemValuesToStore = createAction("GET_SYSTEM_VALUES");
export const getSystemValues = () => async (dispatch) => {
  try {
    var values = await axios.get("SystemActions/GetSystemAction/1");
    dispatch(sendSystemValuesToStore(values.data));
  } catch (error) {
    dispatch(setError(error));
  }
};

export const outOfStockSuccess = createAction("CLEAR_OUTOFSTOCK");
export const updateOutOfStock = (value) => async (dispatch) => {
  try {
    dispatch(outOfStockSuccess);
    let action = "";
    if (value === "Send to failed") {
      action = "sendToFailed";
    }
    if (value === "Reattempt") {
      action = "reattempt";
    }
    await axios.patch(`SystemActions/UpdateOutOfStock/${action}`);
  } catch (error) {
    dispatch(setError(error));
  }
};

export const forcePauseActionSuccess = createAction("FORCE_PAUSE");
export const forcePauseAction = (value) => async (dispatch) => {
  try {
    dispatch(forcePauseActionSuccess(value));
    await axios.patch(`SystemActions/ForcePause/${value}`);
  } catch (error) {
    dispatch(setError(error));
  }
};

export const updateLinePausedStore = createAction("UPDATE_LINEPAUSED");
export const updateLinePaused = (value) => async (dispatch) => {
  try {
    dispatch(updateLinePausedStore(value));
    axios.patch("SystemActions/PatchLinePaused/1", {
      id: 1,
      linePaused: value ? 1 : 0,
    });
  } catch (error) {
    dispatch(setError(error));
  }
};

export const suppressOutOfStockPopup = createAction(
  "SUPPRESS_OUT_OF_STOCK_POPUP"
);

export const updateSkipNotesStore = createAction("UPDATE_SKIP_NOTES");
export const updateSkipNotes = (value) => async (dispatch) => {
  try {
    console.log(value);
    console.log(`Update notes. Set to ${value ? 1 : 0}`);
    dispatch(updateSkipNotesStore(value));
    axios.patch("SystemActions/PatchNotes/1", {
      id: 1,
      skipNotes: value ? 1 : 0,
    });
  } catch (error) {
    dispatch(setError(error));
  }
};

export const updateHpModeStore = createAction("UPDATE_HP_MODE");
export const updateHpMode = (value) => async (dispatch) => {
  try {
    dispatch(updateHpModeStore(value));
    axios.patch("SystemActions/PatchHpMode/1", {
      id: 1,
      hpMode: value ? 1 : 0,
    });
  } catch (error) {
    dispatch(setError(error));
  }
};

export const updateAutoPilotStore = createAction("UPDATE_AUTOPILOT");
export const updateAutoPilot = (value) => async (dispatch) => {
  try {
    dispatch(updateAutoPilotStore(value));
    axios.patch("SystemActions/PatchAutoPilot/1", {
      id: 1,
      autoPilot: value ? 1 : 0,
    });
  } catch (error) {
    dispatch(setError(error));
  }
};

export const setSystemAction = (value) => async (dispatch) => {
  try {
    // dispatch(updateAutoPilotStore(value));
    axios.patch(`SystemActions/SetSystemAction/${value}`);
  } catch (error) {
    dispatch(setError(error));
  }
};

export const updateAutoPilotBoxesStore = createAction(
  "UPDATE_AUTOPILOT_BOXES_STORE"
);
export const updateAutoPilotBoxes = (obj) => async (dispatch) => {
  try {
    dispatch(updateAutoPilotBoxesStore(obj));
    axios.patch("SystemActions/PatchAutoPilotBoxes", {
      autoPilot06: obj.autoPilot06,
      autoPilot12: obj.autoPilot12,
      autoPilot18: obj.autoPilot18,
      autoPilot24: obj.autoPilot24,
      autoPilot36: obj.autoPilot36,
    });
  } catch (error) {
    dispatch(setError(error));
  }
};
