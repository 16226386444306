import React, { useEffect, useState } from "react";
import { Route, Redirect, useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import {
  getDelayedOrders,
  getOrders,
  getOrdersForInvestigation,
} from "./reduxActions/order";
import { getMessages } from "./reduxActions/message";
import { getInfinityNotes } from "./reduxActions/infinityNotes";
import {
  getSystemValues,
  sendSystemValuesToStore,
} from "./reduxActions/system";
import { handleStartStopTime, handleStartStop } from "./reduxActions/timer";
import Layout from "./components/Layout";
import Home from "./components/Home/Home";
import StoreOrders from "./components/StoreOrders/StoreOrders";
import BulkStoreOrders from "./components/StoreOrders/BulkStoreOrders";
import LineInfo from "./components/LineInfo/LineInfo";
import AllOrders from "./components/AllOrders/AllOrders";
import FailedOrders from "./components/FailedOrders/FailedOrders";
import Control from "./components/Control/Control";
import Messaging from "./components/Control/Messaging";
import InfinityNotes from "./components/Control/InfinityNotes";
import ManageNotes from "./components/Control/ManageNotes";
import { HubConnectionBuilder } from "@microsoft/signalr";
import Loader from "./components/Shared/Loader";
import AuthenticatedRoute from "./components/OAuth/AuthenticatedRoute";
import Login from "./components/OAuth/Login";
import StockLevels from "./components/StockLevels/StockLevels";
// import useTimer from "./components/hooks/useTimer";
import BusinessDirect from "./components/BusinessDirect/BusinessDirect"; //
import Dashboard from "./components/Dashboard/Dashboard";
import Delays from "./components/Delays/Delays";
import Claims from "./components/Claims/Claims";
import DelayedOrdersHome from "./components/Delays/DelayedOrdersHome";
import EmailTemplate from "./components/Control/EmailTemplate";
import ThankYou from "./components/ButtonResponse/ThankYou";
import BusinessDirectHistory from "./components/BusinessDirect/BusinessDirectHistory";
import HandpackOrders from "./components/Handpack/HandpackOrders";
import BusinessDirectHome from "./components/BusinessDirect/CreateEditComponents/BusinessDirectHome";
import { getProducts } from "./reduxActions/products";

import "./custom.scss";
import { getStock } from "./reduxActions/stock";
import { getStockRows, getShelfInfo } from "./reduxActions/stock";
import _ from "lodash";
import { ConsoleLogger } from "@microsoft/signalr/dist/esm/Utils";
import PostalZones from "./components/PostalZones/PostalZone";
import OnHoldOrders from "./components/OnHold/OnHoldOrders";
import { Adb } from "@material-ui/icons";
import AdminPanel from "./components/AdminPanel/AdminPanel";
import CustomerDetails from "./components/AdminPanel/CustomerDetails/CustomerDetails";
import CreateOrder from "./components/AdminPanel/CreateOrder/CreateOrder";
import AbandonedCheckout from "./components/AdminPanel/AbandonedCheckout/AbandonedCheckout";
import Autoship from "./components/AdminPanel/Autoship/Autoship";
import Referrals from "./components/AdminPanel/Referrals/Referrals";
import Products from "./components/AdminPanel/Products/Products";
import Errors from "./components/AdminPanel/Errors/Errors";
import NoteQueue from "./components/NoteQueue/NoteQueue";
import PackingStation from "./components/PackingStation/PackingStation";
import BulkPackingStation from "./components/PackingStation/BulkPackingStation";

const App = (props) => {
  const {
    getOrders,
    getSystemValues,
    sendSystemValuesToStore,
    getMessages,
    getInfinityNotes,
    handleStartStopTime,
    getStock,
    // handleStartStop,
    system,
    getStockRows,
    getDelayedOrders,
    getShelfInfo,
    getProducts,
    getOrdersForInvestigation,
  } = props;

  const [firstPath, setFirstPath] = useState(null);
  const location = useLocation();

  useEffect(() => {
    getOrdersForInvestigation();
    getOrders();
    getSystemValues();
    getMessages();
    getInfinityNotes();
    getStock();
    getDelayedOrders();
    getShelfInfo();
    getProducts();

    const connection = new HubConnectionBuilder()
      .withUrl("/robot/system")
      .withAutomaticReconnect({
        nextRetryDelayInMilliseconds: (context) => {
          const retryTimes = [0, 2000, 5000];
          const index =
            context.previousRetryCount < retryTimes.length
              ? context.previousRetryCount
              : retryTimes.length - 1;
          return retryTimes[index];
        },
      })
      .build();

    connection
      .start()
      .then((result) => {
        console.log("Connected!");

        connection.on("UpdateSystemAction", (obj) => {
          sendSystemValuesToStore(obj);
        });
        connection.on("UpdateOrders", () => {
          getOrders();
        });
        connection.on("UpdateMessages", () => {
          getMessages();
        });
        connection.on("UpdateInfinityNotes", () => {
          getInfinityNotes();
        });
        connection.on("StartPackingTimer", () => {
          // handleStartStop(true);
          handleStartStopTime(true);
        });
        connection.on("StopPackingTimer", () => {
          // handleStartStop(false);
          handleStartStopTime(false);
        });
        connection.on("UpdateErrorLogs", () => {
          // GetError();
        });
        connection.on("UpdateStockRows", () => {
          getStockRows();
        });
        connection.on("UpdateDelayedOrders", () => {
          getDelayedOrders();
        });
        connection.on("UpdateShelfInfo", () => {
          getShelfInfo();
        });
      })
      .catch((e) => console.log("Connection failed: ", e));
  }, []);

  useEffect(() => {
    if (firstPath == null) {
      setFirstPath(location.pathname);
    }
  }, [location.pathname]);

  return (
    <React.Fragment>
      <AuthenticatedRoute exact path="/AdminPanel" component={AdminPanel} />
      <AuthenticatedRoute
        exact
        path="/AdminPanel/Customers/"
        component={CustomerDetails}
      />
      <AuthenticatedRoute
        exact
        path="/AdminPanel/AbandonedCheckout"
        component={AbandonedCheckout}
      />
      <AuthenticatedRoute
        exact
        path="/AdminPanel/Autoship"
        component={Autoship}
      />
      <AuthenticatedRoute
        exact
        path="/AdminPanel/CreateOrder/:customerId/:id?"
        component={CreateOrder}
      />
      <AuthenticatedRoute
        exact
        path="/AdminPanel/Referrals"
        component={Referrals}
      />
      <AuthenticatedRoute
        exact
        path="/AdminPanel/Products"
        component={Products}
      />
      <AuthenticatedRoute exact path="/AdminPanel/Errors" component={Errors} />
      <Layout>
        {/* <Redirect exact from="/index.html" to="/"/> */}
        {/* <Redirect
          exact
          push
          from="/index.html"
          to={{
            pathname: "/",
            state: {
              endpoint:
                firstPath !== undefined && firstPath !== "/"
                  ? firstPath
                  : "/Home",
            },
          }}
        /> */}
        <Route exact path="/" component={Loader} />
        <Route exact path="/login" component={Login} />
        <AuthenticatedRoute path="/Home" component={Home} />
        <AuthenticatedRoute path="/StoreOrders" component={StoreOrders} />
        <AuthenticatedRoute
          path="/BulkStoreOrders"
          component={BulkStoreOrders}
        />
        <AuthenticatedRoute path="/LineInfo" component={LineInfo} />
        <AuthenticatedRoute path="/NoteQueue" component={NoteQueue} />
        <AuthenticatedRoute path="/PackingStation" component={PackingStation} />
        <AuthenticatedRoute
          path="/BulkPackingStation"
          component={BulkPackingStation}
        />
        <AuthenticatedRoute path="/AllOrders/:id?" component={AllOrders} />
        <AuthenticatedRoute path="/FailedOrders" component={FailedOrders} />
        <AuthenticatedRoute path="/Control" component={Control} />
        <AuthenticatedRoute path="/Messaging" component={Messaging} />
        <AuthenticatedRoute path="/InfinityNotes" component={InfinityNotes} />
        <AuthenticatedRoute path="/StockLevels" component={StockLevels} />
        <AuthenticatedRoute
          path="/BusinessDirect"
          component={BusinessDirectHome}
        />
        {/* <AuthenticatedRoute path="/CourierTracking" component={Dashboard} /> */}
        <AuthenticatedRoute path="/OrdersOnHold" component={OnHoldOrders} />
        {/* <AuthenticatedRoute path="/DelayedOrders" component={Delays} /> */}
        {/* <AuthenticatedRoute path="/DelayedOrders" component={Claims} /> */}
        <AuthenticatedRoute
          path="/DelayedOrders"
          component={DelayedOrdersHome}
        />
        <AuthenticatedRoute path="/EmailTemplate" component={EmailTemplate} />
        <AuthenticatedRoute path="/ManageNotes" component={ManageNotes} />
        <AuthenticatedRoute path="/HandpackOrders" component={HandpackOrders} />
        <AuthenticatedRoute path="/PostalZones" component={PostalZones} />
        <Route
          exact
          path="/ThankYou/:id/:tracking/:status"
          component={ThankYou}
        />
      </Layout>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  orders: state.orders,
  system: state.system,
});

export default connect(mapStateToProps, {
  getOrders,
  getSystemValues,
  sendSystemValuesToStore,
  getMessages,
  getInfinityNotes,
  handleStartStopTime,
  getStock,
  // handleStartStop,
  getStockRows,
  getDelayedOrders,
  getShelfInfo,
  getProducts,
  getOrdersForInvestigation,
})(App);
