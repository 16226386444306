import { combineReducers } from "redux";
import system from "../reducers/systemReducer";
import order from "../reducers/orderReducer";
import message from "../reducers/messageReducer";
import infinityNote from "../reducers/infinityNoteReducer";
import authentication from "../reducers/authenticationReducer";
import timer from "../reducers/timerReducer";
import stock from "./stockReducer";
import snackbar from "./snackbarReducer";
import note from "./noteReducer";
import products from "./productReducer";
export default combineReducers({
  system,
  order,
  authentication,
  message,
  infinityNote,
  timer,
  stock,
  snackbar,
  note,
  products
});
